import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import iconImporters from './icons'

const MaterialDesignIcon = ({ slug = '', className = '' }) => {
  const importIcon = iconImporters[slug]
  if (!importIcon) return null
  const [icon, updateIcon] = useState('')

  useEffect(() => {
    importIcon()
      .then(({ default: icon }) => updateIcon(icon))
      .catch(() => updateIcon(''))
  }, [slug])

  return (
    <span
      className={`dhsv_material_icon ${className}`}
      dangerouslySetInnerHTML={{
        __html: icon,
      }}
    />
  )
}

MaterialDesignIcon.propTypes = {
  slug: PropTypes.string,
  className: PropTypes.string,
}

export default MaterialDesignIcon
