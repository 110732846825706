/*
  render LoadingIndicator as long as 'ready' is not true
*/
import React from 'react'
import PropTypes from 'prop-types'
import LoadingIndicator from 'scripts/components/LoadingIndicator'

const withLoading = WrappedComponent => {
  const WithLoading = props => {
    const { loaded } = props

    if (loaded) {
      return <WrappedComponent {...props} />
    }

    return <LoadingIndicator />
  }

  WithLoading.propTypes = {
    loaded: PropTypes.bool.isRequired,
  }
  return WithLoading
}

export default withLoading
