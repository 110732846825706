import React from 'react'
import PropTypes from 'prop-types'
import ReactSelect from 'react-select'
import ReactSelectAsync from 'react-select/async'
import {
  fieldInputPropTypes,
  fieldMetaPropTypes,
} from 'redux-form/es/propTypes'
import omit from 'ramda/es/omit'
import './styles.scss'

const getOptionValue = ({ value }) => value
const getOptionLabel = ({ label }) => label

// only leave from the default theme what is absolutely necessary
const theme = ({ colors, spacing }) => ({ colors, spacing })

export const Select = ({
  async = false,
  className,
  touched,
  error,
  name = '',
  // eslint-disable-next-line
  label,
  value,
  isClearable = false,
  backspaceRemovesValue = false,
  ...other
}) => {
  const Component = async ? ReactSelectAsync : ReactSelect
  return (
    <>
      <Component
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        {...other}
        value={value}
        className={`dhsv_formcontrol_select field-${name.replace(
          /[^a-z0-9]/gi,
          '-'
        )} ${value ? 'has-value' : ''} ${touched && error ? 'has-error' : ''} ${
          className ? className : ''
        }`}
        classNamePrefix="select"
        backspaceRemovesValue={backspaceRemovesValue}
        isClearable={isClearable}
        theme={theme}
      />
    </>
  )
}

Select.propTypes = {
  async: PropTypes.bool,
  isClearable: PropTypes.bool,
  backspaceRemovesValue: PropTypes.bool,
  touched: PropTypes.bool,
  error: PropTypes.any,
  value: PropTypes.any,
  className: PropTypes.string,
  name: PropTypes.string,
}

const SelectReduxForm = ({
  input: { onChange, value, name },
  meta: { error, touched },
  ...other
}) => (
  <Select
    {...other}
    error={error}
    onChange={onChange}
    touched={touched}
    value={value}
    name={name}
  />
)

SelectReduxForm.propTypes = {
  input: PropTypes.shape(omit(['onDragStart', 'onDrop'], fieldInputPropTypes)),
  meta: PropTypes.shape(fieldMetaPropTypes),
}

export default SelectReduxForm
