import React from 'react'
import Dropzone from 'react-dropzone'
import PropTypes from 'prop-types'
import remove from 'ramda/es/remove'

import IconButton from 'scripts/components/FormControls/IconButton'
import {
  fieldInputPropTypes,
  fieldMetaPropTypes,
} from 'redux-form/es/propTypes'
import Errors, {
  formatBytesAsMebiBytes,
  mkFileExtensionsString,
} from './Errors'
import './styles.scss'

const initialState = {
  accepted: false,
  rejected: false,
  errors: [],
}

const FilesList = ({ files = [], onClickRemove }) => (
  <span className="files-list">
    {files.length
      ? files.map(({ name }, idx) => (
          <p key={idx}>
            {name}
            <IconButton
              onClick={() => onClickRemove(idx)}
              slug="close-circle"
            />
          </p>
        ))
      : null}
  </span>
)

FilesList.propTypes = {
  files: PropTypes.oneOfType([
    PropTypes.oneOf(['']),
    PropTypes.arrayOf(PropTypes.instanceOf(File)),
  ]).isRequired,
  onClickRemove: PropTypes.func.isRequired,
}

class FileInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = initialState
    this.handleRemove = this.handleRemove.bind(this)
    this.onDrop = this.onDrop.bind(this)
  }

  handleRemove(idx) {
    const {
      input: { onChange, value: files = [] },
    } = this.props

    onChange(remove(idx, 1, files))
  }

  onDrop(acceptedFiles, rejectedFiles) {
    const {
      input: { onChange, value = [] },
    } = this.props

    if (rejectedFiles.length) {
      const [{ errors }] = rejectedFiles
      this.setState({
        accepted: false,
        rejected: true,
        errors,
      })
    } else {
      this.setState({
        accepted: true,
        rejected: false,
        errors: [],
      })
      onChange([...value, ...acceptedFiles])
    }
  }

  render() {
    const {
      input: { value: files = [], name },
      meta: { error, touched, warning },
      label,
      placeholder,
      className,
      style,
      maxFiles,
      disabled,
      ...passThrough
    } = this.props

    const { accepted, rejected, errors } = this.state

    const classError = touched && !!error ? 'has-error' : ''
    const classWarning = touched && !!warning ? 'has-warning' : ''

    const acceptClassName = 'accepted'
    const rejectClassName = 'rejected'

    const classAccepted = accepted ? acceptClassName : ''
    const classRejected = rejected ? rejectClassName : ''

    const finalLabel = label || placeholder

    const id = `fileinput-${name}`

    const { accept, maxSize, multiple } = passThrough

    const hasFiles = files.length > 0

    const buttonLabel = (function () {
      if (multiple && !hasFiles) {
        return 'Dateien hochladen'
      }
      if (multiple && hasFiles) {
        return 'weitere Dateien hochladen'
      }
      if (!multiple && hasFiles) {
        return 'andere Datei hochladen'
      }
      return 'Datei hochladen'
    })()

    const buttonClass = hasFiles ? 'btn-secondary' : 'btn-primary'
    const emptySlots = maxFiles - files.length
    const dropzoneDisabled = emptySlots === 0 || disabled

    return (
      <div
        className={`dhsv_formcontrol_file ${
          className || ''
        } ${classError} ${classWarning}`}
        style={style}
      >
        <div className="top">
          <label htmlFor={id}>{finalLabel}</label>
          <Dropzone
            onDrop={this.onDrop}
            className={`drop-zone ${classAccepted} ${classRejected}`}
            activeClassName="active"
            maxFiles={emptySlots}
            disabled={dropzoneDisabled}
            {...passThrough}
          >
            {({ getRootProps, getInputProps }) => (
              <div
                {...getRootProps()}
                className={`drop-zone btn ${buttonClass} ${classAccepted} ${classRejected} ${
                  dropzoneDisabled ? 'disabled' : ''
                }`}
              >
                <input {...getInputProps()} id={id} />
                {buttonLabel}
              </div>
            )}
          </Dropzone>
        </div>
        <FilesList files={files} onClickRemove={this.handleRemove} />
        {rejected && (
          <Errors
            accept={accept}
            errors={errors}
            maxFiles={maxFiles}
            maxSize={maxSize}
          />
        )}
        <p className="notice">
          (Erlaubte Dateiformate: {mkFileExtensionsString(accept)} und maximale
          Dateigröße von {formatBytesAsMebiBytes(maxSize)}
          {maxFiles && `, bis zu ${maxFiles} Dateien`})
        </p>
      </div>
    )
  }
}

FileInput.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  label: PropTypes.node,
  placeholder: PropTypes.node,
  input: PropTypes.shape(fieldInputPropTypes),
  meta: PropTypes.shape(fieldMetaPropTypes),
  maxFiles: PropTypes.number,
  disabled: PropTypes.bool,
}

export default FileInput
