import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Form from './form'
import { submit } from './module'

const WithData = connect(
  () => ({}),
  (dispatch, props) =>
    bindActionCreators(
      {
        onSubmit: formData => submit(formData, props),
      },
      dispatch
    )
)(Form)

export default WithData
