import React, { useState } from 'react'
import ReactModal from 'react-modal'
import RezeptEingabeForm from 'scripts/components/Forms/RezeptEingabe'
import './styles.scss'

const RezeptEingabeModal = () => {
  const [isOpen, updateIsOpen] = useState(false)

  const open = () => updateIsOpen(true)
  const close = () => updateIsOpen(false)

  return (
    <div className="dhsv_rezepteingabe_modal">
      <button type="button" className="btn btn-secondary" onClick={open}>
        Rezept eingeben
      </button>
      <ReactModal
        isOpen={isOpen}
        onRequestClose={close}
        portalClassName="dhsv_rezepteingabe_modal_portal"
        overlayClassName="overlay"
        className="content"
      >
        <span className="ion-close" onClick={close} />
        <RezeptEingabeForm onRequestClose={close} />
      </ReactModal>
    </div>
  )
}

export default RezeptEingabeModal
