import map from 'ramda/es/map'
import filter from 'ramda/es/filter'
import ifElse from 'ramda/es/ifElse'
import compose from 'ramda/es/compose'
import identity from 'ramda/es/identity'
import has from 'ramda/es/has'
import prop from 'ramda/es/prop'
import defaultLabels from 'scripts/shared/validationLabels'
import l10n from 'scripts/shared/l10n'

const {
  forms: {
    validate_required1,
    validate_required2,
    validate_email,
    validate_email_confirmation,
    validate_kassennummer,
    validate_plz,
  },
  validationMessages: { required: validationMessagesRequired },
} = l10n

export const validateFileInput = (
  bytes = 1024 * 1024 * 10,
  count = 10
) => files => {
  if (!Array.isArray(files)) {
    return
  }

  if (files.every(({ size }) => size <= bytes) && files.length <= count) {
    return
  }

  const mbytes = bytes / (1024 * 1024)

  return `Maximal ${count} Dateien á ${mbytes} MB erlaubt!`
}

export const validateRequired = (fields, labels = defaultLabels) => values =>
  fields.reduce(
    (acc, fieldName) =>
      !values[fieldName]
        ? {
            ...acc,
            [fieldName]: `Das Feld ${labels[fieldName]} muss ausgefüllt werden.`,
          }
        : acc,
    {}
  )

export const composeValidations = (...validations) => (values, props) => {
  return [...validations].reverse().reduce((carry, validate) => {
    const results = validate(values, props)

    return {
      ...carry,
      ...results,
      _error:
        carry._error || results._error
          ? [
              ...(carry._error ? carry._error : []),
              ...(results._error ? results._error : []),
            ]
          : undefined,
    }
  }, {})
}

// this adds all messages also to the base validations object
// so we know in the individual fields where an error happened
// to add a has-error class
const addErrorsToObj = obj =>
  Array.isArray(obj._error)
    ? obj._error.reduce(function (carry, [key, message]) {
        return {
          ...carry,
          [key]: message,
        }
      }, obj)
    : obj

// does the same as the above, except it returns an array of [k,v] pairs
// to preserve the order of "fields"
export const validateRequiredAsPairs = (
  fields,
  labels = defaultLabels
) => values =>
  compose(
    addErrorsToObj,
    ifElse(
      ({ length }) => length <= 0,
      () => ({}),
      _error => ({ _error })
    ),
    map(f => {
      if (validationMessagesRequired[f]) {
        return [f, validationMessagesRequired[f]]
      }
      const label = labels[f] || f
      return [f, `${validate_required1} "${label}" ${validate_required2}`]
    }),
    filter(f => !values[f])
  )(fields)

// this is the regex used by the salesforce api
const emailRegex = /^[a-zA-Z0-9._|\\%#~`=?&/$^*!}{+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,20}$/
const validateEmail = str => emailRegex.test(str)

export const validateEmailFields = (fields, labels = defaultLabels) => values =>
  compose(
    addErrorsToObj,
    ifElse(
      ({ length }) => length <= 0,
      () => ({}),
      _error => ({ _error })
    ),
    map(f => [f, `${labels[f] || f}: ${validate_email}`]),
    filter(f => !validateEmail(values[f])),
    filter(f => !!values[f])
  )(fields)

export const validateEmailConfirmationField = (
  confirmationField,
  emailField,
  labels = defaultLabels
) => values =>
  compose(
    addErrorsToObj,
    ifElse(
      ({ length }) => length <= 0,
      () => ({}),
      _error => ({ _error })
    ),
    map(f => [f, `${labels[f] || f}: ${validate_email_confirmation}`]),
    filter(f => values[f] !== values[emailField]),
    filter(f => !!values[f])
  )([confirmationField])

export const validateZipCode = (fields, labels = defaultLabels) => values =>
  compose(
    addErrorsToObj,
    ifElse(
      ({ length }) => length <= 0,
      () => ({}),
      _error => ({ _error })
    ),
    map(f => [f, `${labels[f] || f}: ${validate_plz}`]),
    filter(f => !/^\d{5}$/.test(values[f])),
    filter(f => !!values[f])
  )(fields)

export const validateKassennummer = (
  fields,
  labels = defaultLabels
) => values =>
  compose(
    addErrorsToObj,
    ifElse(
      ({ length }) => length <= 0,
      () => ({}),
      _error => ({ _error })
    ),
    map(f => [f, `${labels[f] || f}: ${validate_kassennummer}`]),
    filter(f => !/^\d{9}$/.test(values[f])),
    filter(f => !!values[f])
  )(fields)

export const unwrapValues = map(ifElse(has('value'), prop('value'), identity))
