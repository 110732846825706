import React from 'react'
import PropTypes from 'prop-types'
import {
  fieldInputPropTypes,
  fieldMetaPropTypes,
} from 'redux-form/es/propTypes'
import { uuidv4 } from 'scripts/util/math'
import './styles.scss'

export class Checkbox extends React.Component {
  constructor(props) {
    super(props)
    this.uuid = uuidv4()
  }

  render() {
    const {
      disabled,
      id = '',
      name = '',
      label,
      placeholder,
      onChange,
      value,
      touched,
      error,
    } = this.props

    const prefix = `checkbox_${name}`
    const finalId = id ? id : `${prefix}-${this.uuid}`

    return (
      <div
        className={`dhsv_formcontrol_checkbox ${
          touched && error ? 'has-error' : ''
        } ${value ? 'has-value' : ''}`}
      >
        <input
          checked={value}
          disabled={disabled}
          name={name}
          id={finalId}
          onChange={e => onChange(e.target.checked)}
          type={'checkbox'}
        />
        {label && (
          <label
            htmlFor={finalId}
            dangerouslySetInnerHTML={{ __html: label }}
          />
        )}
        {placeholder && (
          <label
            htmlFor={finalId}
            dangerouslySetInnerHTML={{ __html: placeholder }}
          />
        )}
      </div>
    )
  }
}

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.node,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.bool,
  touched: PropTypes.bool,
  error: PropTypes.string,
}

const CheckboxReduxForm = ({
  input: { name, onChange, value },
  meta: { error, touched },
  ...other
}) => (
  <Checkbox
    {...other}
    name={name}
    onChange={checked => onChange(checked || '')}
    value={value ? true : false}
    error={error}
    touched={touched}
  />
)

CheckboxReduxForm.propTypes = {
  id: PropTypes.string,
  input: PropTypes.shape(fieldInputPropTypes),
  meta: PropTypes.shape(fieldMetaPropTypes),
}

export default CheckboxReduxForm
