import React from 'react'
import PropTypes from 'prop-types'

const RezeptMainContent = ({ source_id }) => {
  switch (parseInt(source_id)) {
    case 1297:
      return (
        <div className="main-content main-content-spacer">
          <p>
            Nutzen Sie unseren kostenlosen Rezeptservice und senden Sie Ihr
            Rezept online an Ihren Homecare-Partner vor Ort.
            <br />
            So reichen Sie Ihr Rezept online bei uns ein:
          </p>
          <ol>
            <li>Wählen Sie Ihren Homecare-Partner vor Ort aus.</li>
            <li>Geben Sie Ihre Daten ein.</li>
            <li>
              Laden Sie Ihr Rezept als Datei hoch oder füllen Sie die
              Eingabemaske aus.
            </li>
            <li>Akzeptieren Sie die Datenschutzerklärung.</li>
            <li>Klicken Sie auf “Absenden”.</li>
            <li>
              Wir empfehlen Ihnen die Kontaktdaten Ihres ausgewählten
              Homecare-Partners für Rückfragen herunterzuladen.
            </li>
            <li>
              Der Homecare-Partner setzt sich mit Ihnen telefonisch oder per
              E-Mail in Verbindung, Sie besprechen eine gemeinsame Versorgung
              und danach wird Ihr Rezept bearbeitet.
            </li>
            <li>
              Nachdem eine verbindliche Einigung und Auftragserteilung erzielt
              wurde, wird Ihre Bestellung zusammengestellt und kann von Ihnen
              bei Ihrem Homecare-Partner vor Ort abgeholt oder nach Absprache an
              Sie ausgeliefert werden.
            </li>
          </ol>
        </div>
      )

    default:
      return (
        <div className="main-content main-content-spacer">
          <p>
            Nutzen Sie unseren kostenlosen Rezeptservice und senden Sie Ihr
            Rezept online an Ihr Sanitätshaus vor Ort.
            <br />
            So reichen Sie Ihr Rezept online bei uns ein:
          </p>
          <ol>
            <li>Wählen Sie Ihr Sanitätshaus vor Ort aus.</li>
            <li>Geben Sie Ihre Daten ein.</li>
            <li>
              Laden Sie Ihr Rezept als Datei hoch oder füllen Sie die
              Eingabemaske aus.
            </li>
            <li>Akzeptieren Sie die Datenschutzerklärung.</li>
            <li>Klicken Sie auf “Absenden”.</li>
            <li>
              Wir empfehlen Ihnen die Kontaktdaten Ihres ausgewählten
              Sanitätshauses für Rückfragen herunterzuladen.
            </li>
            <li>
              Das Sanitätshaus setzt sich mit Ihnen telefonisch oder per E-Mail
              in Verbindung, Sie besprechen eine gemeinsame Versorgung und
              danach wird Ihr Rezept bearbeitet.
            </li>
            <li>
              Nachdem eine verbindliche Einigung und Auftragserteilung erzielt
              wurde, wird Ihre Bestellung zusammengestellt und kann von Ihnen in
              Ihrem Sanitätshaus vor Ort abgeholt oder nach Absprache an Sie
              ausgeliefert werden.
            </li>
          </ol>
        </div>
      )
  }
}

RezeptMainContent.propTypes = {
  source_id: PropTypes.number,
}

export default RezeptMainContent
