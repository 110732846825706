import React from 'react'
import PropTypes from 'prop-types'
import l10n from 'scripts/shared/l10n'
import './styles.scss'

const {
  forms: { notice_network_error, notice_support },
} = l10n

const NetworkError = ({ hasNetworkError }) =>
  hasNetworkError && (
    <p className="dhsv_network_error">
      {notice_network_error}
      <br />
      {notice_support}
    </p>
  )

NetworkError.propTypes = {
  hasNetworkError: PropTypes.bool,
}

export default NetworkError
