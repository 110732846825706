import reduxForm from 'redux-form/es/reduxForm'
import {
  composeValidations,
  validateEmailFields,
  validateRequiredAsPairs,
} from 'scripts/util/form'
import { formName } from './module'
import requiredFields from './requiredFields'

import Component from './component'

const validate = composeValidations(
  validateEmailFields(['email']),
  (values, props) => validateRequiredAsPairs(requiredFields)(values, props)
)

const WithForm = reduxForm({
  form: formName,
  validate,
})(Component)

export default WithForm
