/*
 inject a "label" prop based on the passed in "name" prop
 */
import React from 'react'
import PropTypes from 'prop-types'
import fieldLabels from 'scripts/shared/labels'

const withLabel = (WrappedComponent, labels = fieldLabels) => {
  const WithLabel = props => {
    const { name, placeholder, required } = props
    const label = placeholder
      ? placeholder
      : name
      ? labels[name]
        ? labels[name]
        : name
      : ''
    const finalLabel = label + (required ? ' *' : '')
    return <WrappedComponent {...props} placeholder={finalLabel} />
  }

  WithLabel.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
  }
  return WithLabel
}

export default withLabel
