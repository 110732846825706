import React from 'react'
import PropTypes from 'prop-types'
import {
  fieldInputPropTypes,
  fieldMetaPropTypes,
} from 'redux-form/es/propTypes'
import pick from 'ramda/es/pick'
import identity from 'ramda/es/identity'
import withClearable from 'scripts/higher-order-components/withClearable'
import './styles.scss'

class TextFieldBase extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange({ target: { value } }) {
    const { maxLength, onChange } = this.props
    return onChange(maxLength ? value.slice(0, maxLength) : value)
  }

  render() {
    const { InputComponent, ...other } = this.props
    if (InputComponent) return <InputComponent {...other} />

    const propsToPass = pick(
      [
        'name',
        'disabled',
        'onClick',
        'onBlur',
        'placeholder',
        'readOnly',
        'value',
      ],
      this.props
    )

    return this.props.multiline ? (
      <textarea {...propsToPass} onChange={this.handleChange} />
    ) : (
      <input {...propsToPass} onChange={this.handleChange} type="text" />
    )
  }
}

TextFieldBase.defaultProps = {
  maxLength: 0,
  onChange: identity,
}

TextFieldBase.propTypes = {
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  InputComponent: PropTypes.func,
  maxLength: PropTypes.number,
}

const TextFieldWithLabel = ({ error, label, name, touched, ...other }) => (
  <div
    className={`dhsv_formcontrol_textfield field-${name} ${
      touched && error ? 'has-error' : ''
    } ${other.value ? 'has-value' : ''}`}
  >
    {label && <label>{label}</label>}
    <TextFieldBase name={name} {...other} />
  </div>
)

TextFieldWithLabel.propTypes = {
  error: PropTypes.string,
  label: PropTypes.node,
  name: PropTypes.string,
  touched: PropTypes.bool,
}

export const TextField = withClearable(TextFieldWithLabel)

const TextFieldReduxForm = ({
  input: { name, onChange, value, onBlur },
  meta: { error, touched },
  ...other
}) => (
  <TextField
    name={name}
    error={error}
    onChange={onChange}
    onBlur={onBlur}
    touched={touched}
    value={value}
    {...other}
  />
)

TextFieldReduxForm.propTypes = {
  input: PropTypes.shape(fieldInputPropTypes),
  meta: PropTypes.shape(fieldMetaPropTypes),
}

export default TextFieldReduxForm
