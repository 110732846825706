import React from 'react'
import './styles.scss'

const LoadingIndicator = () => {
  return (
    <div className="dhsv_loading_indicator">
      <span />
      <span />
      <span />
    </div>
  )
}

export default LoadingIndicator
