import { useState, useEffect } from 'react'
import q from 'query-string'
import l10n from 'scripts/shared/l10n'

const { lang } = l10n

const useAnchlussHaeuser = () => {
  const query = {
    lang,
  }
  const [places, updatePlaces] = useState([])
  const [loaded, updateLoaded] = useState(false)

  useEffect(() => {
    fetch(`/wp-json/dhsv/v1/places?${q.stringify(query)}`).then(response =>
      response.status === 200
        ? response.json().then(data => {
            updatePlaces(data)
            updateLoaded(true)
          })
        : null
    )
  }, [])

  return {
    places,
    loaded,
  }
}

export default useAnchlussHaeuser
