import haversine from 'haversine'
import { stringify } from 'query-string'

import globalData from 'scripts/shared/globalData'

const { googleMapsApiKey } = globalData

const GEOCODING_API = 'https://maps.googleapis.com/maps/api/geocode/json'

export const geocode = async address => {
  const query = {
    // append "Germany" to address. since all users are located there,
    // this prevents problems with location names, which are not globally unique
    address: `${address} Germany`,
    key: googleMapsApiKey,
  }
  const queryString = stringify(query)
  try {
    const response = await fetch(`${GEOCODING_API}?${queryString}`)

    if (response.status === 200) {
      return response.json()
    }
  } catch (e) {
    console.error(e)
  }
  return {}
}

const coordNames = ({ lat, lng }) => ({ latitude: lat, longitude: lng })

export const isWithinDistance = (start, end, distance) =>
  haversine(coordNames(start), coordNames(end), {
    threshold: distance,
  })
