import { combineReducers } from 'redux'
import form from 'redux-form/es/reducer'
import browser from './browser'
import router from './router'
import networkError from './networkError'

const appReducer = combineReducers({
  browser,
  form,
  location: router,
  networkError,
})

export default appReducer
