import addIndex from 'ramda/es/addIndex'
import chain from 'ramda/es/chain'
import compose from 'ramda/es/compose'
import join from 'ramda/es/join'

const chainIndexed = addIndex(chain)

export const printf = (str = '', subst = '') => {
  const substArr = !Array.isArray(subst) ? [subst] : subst
  return compose(
    join(''),
    chainIndexed((val, idx) => {
      return !!substArr[idx] || !isNaN(parseFloat(substArr[idx]))
        ? [val, substArr[idx]]
        : [val]
    })
  )(typeof str === 'string' ? str.split('%s') : [])
}

// since the url field does not really contain an URL, we need to add the protocol
// and hope all of the sites support https
export const fixWebsiteUrl = url => `https://${url}`

// strip html tags
// see: https://stackoverflow.com/questions/5002111/how-to-strip-html-tags-from-string-in-javascript/5002161#5002161
export const stripTags = str => {
  const div = document.createElement('div')
  div.innerHTML = str
  return div.textContent || div.innerText || ''
}
