import React, { Fragment, useEffect } from 'react'
import { createFilter } from 'react-select'
import compose from 'ramda/es/compose'
import find from 'ramda/es/find'
import map from 'ramda/es/map'
import sortBy from 'ramda/es/sortBy'
import prop from 'ramda/es/prop'
import propEq from 'ramda/es/propEq'
import { parse, stringify } from 'query-string'

import Select from 'scripts/components/FormControls/Select'

import withLoading from 'scripts/higher-order-components/withLoading'
import useAnchlussHaeuser from 'scripts/hooks/useAnchlussHaeuser'

import { geocode, isWithinDistance } from 'scripts/util/geocoding'
import globalData from 'scripts/shared/globalData'

const { selectAnschlusshausRadius } = globalData

const WithLoading = withLoading(Select)

const mkOption = ({
  id,
  firmenname,
  location,
  plz,
  ort,
  source_id,
  strasse_hausnummer,
  url_datenschutzhinweise,
  url_website,
}) => {
  return {
    label: `${plz} ${ort}, ${firmenname}`,
    value: id,
    location,
    source_id,
    strasse_hausnummer,
    url_datenschutzhinweise,
    url_website,
  }
}
const mkOptions = compose(map(mkOption), sortBy(prop('plz')))

const defaultFilter = createFilter()

const filterOptions = async (
  options,
  userInput,
  maxDistance = selectAnschlusshausRadius || 20
) => {
  const filteredOptions = options.filter(option =>
    defaultFilter(option, userInput)
  )
  if (filteredOptions.length) {
    return filteredOptions
  }

  const { results: locations } = await geocode(userInput)
  if (locations && locations.length) {
    const [
      {
        geometry: { location: searchInputLocation },
      },
    ] = locations

    return options.filter(({ location: optionLocation }) =>
      isWithinDistance(searchInputLocation, optionLocation, maxDistance)
    )
  }

  return []
}

const WithPlaces = ps => {
  const { places, loaded } = useAnchlussHaeuser()

  const options = mkOptions(places)

  // preselect the 'AnschlussHaus' via query string 'sid'
  useEffect(() => {
    if (loaded) {
      const { pathname, search } = window.location
      const { sid, ...other } = parse(search)
      const optionToSelect = find(propEq('source_id', sid), options)
      if (optionToSelect) {
        ps.setSID(sid)
        ps.input.onChange(optionToSelect)
      } else {
        const qs = stringify(other)
        window.history.replaceState(
          null,
          '',
          `${pathname}${qs ? `?${qs}` : ''}`
        )
      }
    }
  }, [loaded])

  let namingAdjustment = ps.input.value && ps.input.value.source_id == 1297
  if (ps.input.value) {
    ps.setSID(ps.input.value.source_id)
  }
  return (
    <Fragment>
      <WithLoading
        async
        formatOptionLabel={({ label, strasse_hausnummer }) => (
          <Fragment>
            <span>{label}</span>
            <span className="small">{strasse_hausnummer}</span>
          </Fragment>
        )}
        defaultOptions={options}
        loaded={loaded}
        loadOptions={userInput => filterOptions(options, userInput)}
        {...ps}
      />
      {ps.input.value && (
        <div>
          <p className="extra-box">
            {namingAdjustment ? 'Der' : 'Das'} von Ihnen ausgewählte{' '}
            {namingAdjustment ? 'Homecare-Partner' : 'Sanitätshaus'} benötigt
            Ihr Kassen- oder Privatrezept im Original, bevor Ihre Bestellung
            übergeben/ ausgeliefert werden kann. Entweder bringen Sie es zur
            Abholung mit oder senden es nach Absprache in einem Umschlag an{' '}
            {namingAdjustment
              ? 'den ausgewählten Homecare-Partner'
              : 'das ausgewählte Sanitätshaus'}
            .
          </p>
        </div>
      )}
    </Fragment>
  )
}

export default WithPlaces
