/*
 extend input fields by adding a "isClearable" prop
 to trigger rendering a X for clearing the field's value
 */
import React from 'react'
import PropTypes from 'prop-types'
import './withClearable.scss'

const withClearable = WrappedComponent => {
  class WithClearable extends React.Component {
    constructor(props) {
      super(props)
      this.handleClear = this.handleClear.bind(this)
    }

    handleClear() {
      const { onChange, emptyValue } = this.props
      onChange(emptyValue)
    }

    render() {
      const { isClearable, ClearComponent, value, emptyValue } = this.props

      const wrappedComponent = <WrappedComponent {...this.props} />
      const canClear = value !== emptyValue

      return isClearable ? (
        <div className="hoc with-clearable">
          {wrappedComponent}
          <ClearComponent onClick={this.handleClear} canClear={canClear} />
        </div>
      ) : (
        wrappedComponent
      )
    }
  }

  const ClearComponent = ({ onClick, canClear }) =>
    canClear ? <i className="ion-android-close" onClick={onClick} /> : null

  ClearComponent.propTypes = {
    onClick: PropTypes.func.isRequired,
    canClear: PropTypes.bool,
  }

  WithClearable.defaultProps = {
    isClearable: false,
    emptyValue: '',
    ClearComponent,
  }

  WithClearable.propTypes = {
    isClearable: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
    emptyValue: PropTypes.any,
    ClearComponent: PropTypes.func,
  }
  return WithClearable
}

export default withClearable
