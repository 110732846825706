import map from 'ramda/es/map'
import { SubmissionError } from 'redux-form/es/SubmissionError'
import toPairs from 'ramda/es/toPairs'
import reduce from 'ramda/es/reduce'
import compose from 'ramda/es/compose'
import partition from 'ramda/es/partition'
import l10n from 'scripts/shared/l10n'
import { CLEAR_ERROR, REQUEST_FAILED } from './networkError'

const isFileOrOrArrayOfFiles = v =>
  v instanceof File || (v instanceof Array && v[0] && v[0] instanceof File)

const toFormData = compose(
  reduce(
    (formData, [k, v]) => (
      Array.isArray(v)
        ? v.forEach((_v, i) => formData.append(`${k}_${i}`, _v))
        : formData.append(k, v),
      formData
    ),
    new FormData()
  ),
  toPairs
)

const getFormData = compose(
  toFormData,
  ([files, noFiles]) => ({
    ...files,
    fields: JSON.stringify(noFiles),
  }),
  partition(isFileOrOrArrayOfFiles)
)

export const submitForm = ({
  formName,
  url = '/wp-json/dhsv/v1/form/submit',
  data,
  successType,
  failureType,
}) => dispatch => {
  dispatch({ type: CLEAR_ERROR })

  return fetch(url, {
    method: 'POST',
    body: getFormData({ ...data, formName }),
  })
    .catch(error => {
      dispatch({
        meta: {
          data,
          error,
          reason: 'network error (no response)',
        },
        type: failureType,
      })

      dispatch({ type: REQUEST_FAILED, message: error.message })

      throw error
    })
    .then(response => {
      if (!response.ok) {
        dispatch({
          meta: {
            data,
            response,
            reason: 'network error (with response)',
          },
          type: failureType,
        })

        const message = response.statusText
          ? response.statusText
          : response.status

        dispatch({ type: REQUEST_FAILED, message })

        throw new Error(message)
      }

      return response.json().then(json => {
        if (!json.success) {
          dispatch({
            meta: {
              data,
              response,
              reason: 'submission failure',
            },
            type: failureType,
          })

          const {
            forms: { notice_support },
            submissionFailures,
          } = l10n

          throw new SubmissionError({
            _error: map(msg => ['_submission_failure', msg], [
              submissionFailures[json.code] || json.code,
              notice_support,
            ]),
          })
        }

        dispatch({
          meta: { data, response, json },
          type: successType,
        })

        return { response, json }
      })
    })
}
