import formActions from 'redux-form/es/actions'
import { dateGerman } from 'scripts/util/date'
import { unwrapValues } from 'scripts/util/form'

const { change } = formActions

export const formName = 'rezeptEingabe'

export const submit = (formData, { onRequestClose }) => dispatch => {
  const { name, ...rest } = unwrapValues(formData)
  const today = dateGerman(new Date())
  const filename = `rezept_${name
    .replace(' ', '_')
    .replace('.', '')
    .toLowerCase()}_${today}.pdf`

  /* global System */
  return System.import('scripts/util/renderRezeptPdf')
    .then(render => render.default({ name, ...rest }))
    .then(blob => {
      dispatch(change('rezeptUpload', 'rezepte', [new File([blob], filename)]))
      onRequestClose()
    })
}
