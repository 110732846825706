import React, { lazy, Suspense } from 'react'
import LoadingIndicator from 'scripts/components/LoadingIndicator'

/* global System */
const FormSuccess = lazy(() => System.import('./component'))

const Load = props => (
  <Suspense fallback={<LoadingIndicator />}>
    <FormSuccess {...props} />
  </Suspense>
)

export default Load
