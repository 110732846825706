import React from 'react'
import PropTypes from 'prop-types'
import {
  fieldInputPropTypes,
  fieldMetaPropTypes,
} from 'redux-form/es/propTypes'
import omit from 'ramda/es/omit'
import './styles.scss'

const Item = ({ value, label, name, checked, onChange }) => {
  const id = `dhsv_radio_${name}_${value}`
  return (
    <div>
      <input
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  )
}

Item.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
}

export const RadioButtons = ({
  className = '',
  error,
  name = '',
  value,
  options = [],
  onChange,
  touched,
}) => (
  <div
    className={`dhsv_formcontrol_radiobutton field-${name.toLowerCase()} ${
      touched && error ? 'has-error' : ''
    } ${className} `}
  >
    {options.map(item => (
      <Item
        {...item}
        key={item.value}
        checked={value && item.value === value.value ? true : false}
        name={name}
        onChange={() => onChange(item)}
      />
    ))}
  </div>
)

RadioButtons.propTypes = {
  onChange: PropTypes.func,
  touched: PropTypes.bool,
  error: PropTypes.any,
  value: PropTypes.any,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.string,
      label: PropTypes.string.string,
    })
  ),
}

const RadioButtonsReduxForm = ({
  input: { onChange, value, name },
  meta: { error, touched },
  ...other
}) => {
  return (
    <RadioButtons
      {...other}
      error={error}
      onChange={onChange}
      touched={touched}
      value={value}
      name={name}
    />
  )
}

RadioButtonsReduxForm.propTypes = {
  input: PropTypes.shape(omit(['onDragStart', 'onDrop'], fieldInputPropTypes)),
  meta: PropTypes.shape(fieldMetaPropTypes),
}

export default RadioButtonsReduxForm
