import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import identity from 'ramda/es/identity'
import Component from './component'

const sForm = ({ form }, { form: formName }) => form[formName] || {}
const sErrors = createSelector(sForm, form => form.error || [])
const sFields = createSelector(sForm, form => form.fields || [])
const sFieldsToHide = createSelector(
  (_, { hideFields }) => hideFields || [],
  identity
)
const sFinalErrors = createSelector(
  sFields,
  sErrors,
  sFieldsToHide,
  (fields, errors, fieldsToHide) =>
    fields && Array.isArray(errors)
      ? errors.filter(
          ([key]) =>
            key === '_submission_failure' ||
            (!fieldsToHide.includes(key) &&
              fields &&
              fields[key] &&
              fields[key].touched)
        )
      : []
)

const WithData = connect((state, props) => ({
  errors: sFinalErrors(state, props),
}))(Component)

WithData.propTypes = {
  form: PropTypes.string.isRequired,
  hideFields: PropTypes.arrayOf(PropTypes.string),
}

export default WithData
