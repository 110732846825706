import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { formPropTypes } from 'redux-form/es/propTypes'
import withSuccess from 'scripts/higher-order-components/withSuccess'
import NetworkError from 'scripts/components/FormControls/NetworkError'
import ValidationMessages from 'scripts/components/FormControls/ValidationMessages'
import TextField from 'scripts/components/FormControls/TextField'
import Select from 'scripts/components/FormControls/Select'
import SelectAnschlussHaus from 'scripts/components/FormControls/SelectAnschlussHaus'
import FileInput from 'scripts/components/FormControls/FileInput'
import Checkbox from 'scripts/components/FormControls/Checkbox'
import FormSuccess from './FormSuccess'
import RezeptEingabeModal from 'scripts/components/Forms/RezeptEingabeModal'
import AllowedMimeTypes from 'scripts/shared/rezeptUploadAllowedMimeTypes.json'
import FormField from './FormField'
import l10n from 'scripts/shared/l10n'
import './styles.scss'
import { parse } from 'query-string'
import RezeptMainContent from '../RezeptMainContent'

const {
  forms: { submit },
} = l10n

const mkOption = value => ({
  label: value,
  value,
})

const anredeOptions = ['Herr', 'Frau', 'Sonstige'].map(mkOption)
const maxUploadSize = 10 * Math.pow(2, 20) // 2 ** 20 equals 1 MiB

const RezeptUploadForm = ({
  handleSubmit,
  submitting,
  form,
  labelsWithUrl: { rezeptUploadConfirmDatenschutz },
}) => {
  const submittingClass = submitting ? 'submitting' : ''
  const formNameLower = form.toLowerCase()
  const [source_id, setSource_id] = useState()

  useEffect(() => {
    const { sid } = parse(window.location.search)
    setSource_id(sid)
  }, [])

  return (
    <div>
      <RezeptMainContent source_id={source_id} />
      <div className="row">
        <div className="col-12">
          <form
            className={`dhsv_${formNameLower}_form dhsv_form ${submittingClass}`}
            onSubmit={handleSubmit}
          >
            <div className="row">
              <div className="col-12">
                <fieldset disabled={source_id}>
                  <FormField
                    name="anschlussHaus"
                    component={SelectAnschlussHaus}
                    setSID={setSource_id}
                    className={source_id ? 'remove-select-indicator' : ''}
                  />
                </fieldset>
              </div>
              <div className="col-12">
                <FormField
                  name="anrede"
                  component={Select}
                  options={anredeOptions}
                />
              </div>
              <div className="col-12 col-md-6">
                <FormField
                  name="vorname"
                  component={TextField}
                  maxLength={40}
                />
              </div>
              <div className="col-12 col-md-6">
                <FormField name="name" component={TextField} maxLength={40} />
              </div>
              <div className="col-12 col-md-6">
                <FormField name="email" component={TextField} maxLength={40} />
              </div>
              <div className="col-12 col-md-6">
                <FormField
                  name="telefon"
                  component={TextField}
                  maxLength={40}
                />
              </div>
              <div className="col-12 col-md-6">
                <FormField
                  name="rezepte"
                  component={FileInput}
                  accept={AllowedMimeTypes}
                  maxSize={maxUploadSize}
                  maxFiles={5}
                  multiple={true}
                />
              </div>
              <div className="col-12 col-md-6">
                <FormField
                  name="Rezeptgebührbefreiung"
                  component={FileInput}
                  accept={AllowedMimeTypes}
                  maxSize={maxUploadSize}
                  maxFiles={1}
                  multiple={true}
                />
              </div>
              <div className="col-12 col-md-6 pb-4">
                <RezeptEingabeModal />
              </div>
              <div className="col-12">
                <FormField
                  name="bemerkungen"
                  component={TextField}
                  multiline
                  maxLength={500}
                />
              </div>
              <div className="col-12">
                <FormField
                  name="rezeptUploadConfirmVerordnung"
                  component={Checkbox}
                />
              </div>
              <div className="col-12">
                <FormField
                  name="rezeptUploadConfirmDatenschutz"
                  component={Checkbox}
                  placeholder={rezeptUploadConfirmDatenschutz}
                />
              </div>
              <div className="col-12">
                <FormField
                  name="rezeptUploadConfirmGesundheitsdaten"
                  component={Checkbox}
                />
              </div>
              <div className="col-12">
                <FormField
                  name="rezeptUploadConfirmKontakt"
                  component={Checkbox}
                />
              </div>
              <div className="col-12">
                <NetworkError />
                <ValidationMessages
                  form={form}
                  hideFields={[
                    'rezeptUploadConfirmVerordnung',
                    'rezeptUploadConfirmGesundheitsdaten',
                    'rezeptUploadConfirmKontakt',
                  ]}
                />
              </div>
              <div className="buttons d-flex flex-row-reverse col-12 px-4 mt-4">
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={submitting}
                >
                  {submit}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

RezeptUploadForm.propTypes = {
  ...formPropTypes,
  labelsWithUrl: PropTypes.shape({
    rezeptUploadConfirmGesundheitsdaten: PropTypes.string,
  }),
}

export default withSuccess(FormSuccess)(RezeptUploadForm)
