import React from 'react'
import PropTypes from 'prop-types'

import Button from 'scripts/components/FormControls/Button'
import MaterialDesignIcon from 'scripts/components/MaterialDesignIcon'

import './styles.scss'

const IconButton = ({ className = '', slug, ...props }) => (
  <Button
    className={['dhsv_icon_button', className].filter(val => !!val).join(' ')}
    {...props}
  >
    <MaterialDesignIcon slug={slug} />
  </Button>
)

IconButton.propTypes = {
  className: PropTypes.string,
  slug: PropTypes.string.isRequired,
}

export default IconButton
