import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

const ValidationMessages = ({ errors }) =>
  errors.length ? (
    <div className="dhsv_validation_messages">
      {errors.map(([field, message], key) => (
        <p key={key} className={`error field_${field}`}>
          {message}
        </p>
      ))}
    </div>
  ) : null

ValidationMessages.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
}

export default ValidationMessages
