import formActions from 'redux-form/es/actions'
import { submitForm } from './form'
import { unwrapValues } from 'scripts/util/form'

const { destroy } = formActions

export const formName = 'rezeptUpload'

const SUBMIT_FORM = `dhsv/san002/${formName}Form/SUBMIT_FORM`
const SUBMIT_FORM_SUCCESS = `dhsv/san002/${formName}Form/SUBMIT_FORM_SUCCESS`
const SUBMIT_FORM_FAILURE = `dhsv/san002/${formName}Form/SUBMIT_FORM_FAILURE`

// *********** WITH ReCaptcha **************
// export const submit = (
//   formData,
//   { googleReCaptchaProps: { executeRecaptcha } }
// ) => dispatch => {
//   return executeRecaptcha(formName).then(reCaptchaToken => {
//     dispatch(destroy('rezeptEingabe'))
//     dispatch({ type: SUBMIT_FORM, meta: { formData } })
//
//     return dispatch(
//       submitForm({
//         formName,
//         data: {
//           ...unwrapValues(formData),
//           reCaptchaToken,
//         },
//         successType: SUBMIT_FORM_SUCCESS,
//         failureType: SUBMIT_FORM_FAILURE,
//       })
//     ).then(() => {
//       // add tracking here
//     })
//   })
// }

export const submit = formData => dispatch => {
  dispatch(destroy('rezeptEingabe'))
  dispatch({ type: SUBMIT_FORM, meta: { formData } })

  return dispatch(
    submitForm({
      formName,
      data: {
        ...unwrapValues(formData),
      },
      successType: SUBMIT_FORM_SUCCESS,
      failureType: SUBMIT_FORM_FAILURE,
    })
  ).then(() => {
    // add tracking here
  })
}

export const successFields = [
  'anschlussHaus',
  'anrede',
  'vorname',
  'name',
  'email',
  'telefon',
  'bemerkungen',
  'rezeptUploadConfirmVerordnung',
  'rezeptUploadConfirmDatenschutz',
  'rezeptUploadConfirmGesundheitsdaten',
  'rezeptUploadConfirmKontakt',
]

export const successFieldsAnschlussHaus = [
  'firmenname',
  'telefon',
  'url_website',
]

export const renameAnschlussHausKeys = str => `anschlussHaus_${str}`
