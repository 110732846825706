import reduxForm from 'redux-form/es/reduxForm'
import {
  composeValidations,
  validateKassennummer,
  validateRequiredAsPairs,
  validateZipCode,
} from 'scripts/util/form'
import { formName } from './module'
import requiredFields from './requiredFields'

import Component from './component'

const validate = composeValidations(
  validateKassennummer(['kassennummer']),
  (values, props) => validateRequiredAsPairs(requiredFields)(values, props),
  validateZipCode(['plz', 'praxis_plz'])
)

const WithForm = reduxForm({
  destroyOnUnmount: false,
  form: formName,
  initialValues: {},
  validate,
})(Component)

export default WithForm
