import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const Button = ({ children, className = '', ...props }) => (
  <button
    className={['dhsv_button', className].filter(val => !!val).join(' ')}
    type="button"
    {...props}
  >
    {children}
  </button>
)

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export default Button
