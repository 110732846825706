import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { formValueSelector } from 'redux-form'
import compose from 'ramda/es/compose'
import fromPairs from 'ramda/es/fromPairs'
import map from 'ramda/es/map'
import fieldLabels from 'scripts/shared/labels'
import { printf } from 'scripts/util/string'
import { unwrapValues } from 'scripts/util/form'
import Form from './form'
import { formName, submit, successFields } from './module'

const { rezeptUploadConfirmDatenschutz } = fieldLabels

const sFields = formValueSelector(formName)
const sAnschlussHaus = state => sFields(state, 'anschlussHaus')
const sSuccessFields = state =>
  compose(
    unwrapValues,
    fromPairs,
    map(f => [f, sFields(state, f)])
  )(successFields)

const sLabelsWithUrl = createSelector(
  sAnschlussHaus,
  ({ url_datenschutzhinweise } = {}) =>
    map(
      label =>
        printf(
          label,
          url_datenschutzhinweise
            ? [url_datenschutzhinweise, 'rel="noopener noreferrer"']
            : ['#', '']
        ),
      {
        rezeptUploadConfirmDatenschutz,
      }
    )
)

const WithData = connect(
  state => ({
    labelsWithUrl: sLabelsWithUrl(state),
    successFields: sSuccessFields(state),
  }),
  (dispatch, props) =>
    bindActionCreators(
      {
        onSubmit: formData => dispatch => dispatch(submit(formData, props)),
      },
      dispatch
    )
)(Form)

export default WithData
