import React, { createRef, useEffect, useState } from 'react'
// import PropTypes from 'prop-types'
import { formPropTypes } from 'redux-form/es/propTypes'
import NetworkError from 'scripts/components/FormControls/NetworkError'
import ValidationMessages from 'scripts/components/FormControls/ValidationMessages'
import RadioButtons from 'scripts/components/FormControls/RadioButtons'
import TextField from 'scripts/components/FormControls/TextField'
import FormField from './FormField'
import l10n from 'scripts/shared/l10n'
import './styles.scss'

const {
  RezeptEingabeForm: { heading },
  forms: { apply, prev },
} = l10n

export const gebuehrenfreiChoices = [
  { value: 'ja', label: '' },
  { value: 'nein', label: '' },
]

const RezeptEingabeForm = ({
  handleSubmit,
  submitting,
  form,
  onRequestClose,
}) => {
  const submittingClass = submitting ? 'submitting' : ''
  const formNameLower = form.toLowerCase()

  const rezeptContainerRef = createRef()
  const rezeptFormRef = createRef()
  const [scale, setScale] = useState(1)
  const rezeptFormStyle = { transform: `translate(-50%, -50%) scale(${scale})` }
  useEffect(() => {
    const handleResize = () => {
      if (rezeptContainerRef.current && rezeptFormRef.current) {
        const {
          current: { clientHeight, clientWidth },
        } = rezeptContainerRef

        const {
          current: { offsetHeight, offsetWidth },
        } = rezeptFormRef

        const scale = Math.min(
          clientHeight / offsetHeight,
          clientWidth / offsetWidth
        )

        setScale(scale)
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  })

  return (
    <div className="row">
      <div className="col-12">
        <form
          className={`dhsv_${formNameLower}_form dhsv_form ${submittingClass}`}
          onSubmit={e => {
            // we stop the propagation of the submit event to prevent, it triggering submit() on the parent form
            e.stopPropagation()
            handleSubmit(e)
          }}
        >
          <h4>{heading}</h4>
          <div className="rezept-container" ref={rezeptContainerRef}>
            <div className="rezept" ref={rezeptFormRef} style={rezeptFormStyle}>
              <FormField
                name="krankenkasse"
                component={TextField}
                maxLength={40}
              />

              <FormField name="name" component={TextField} maxLength={40} />
              <FormField name="vorname" component={TextField} maxLength={40} />
              <FormField
                name="anschrift"
                component={TextField}
                maxLength={40}
              />
              <FormField
                name="geburtsdatum"
                component={TextField}
                maxLength={40}
              />
              <FormField name="plz" component={TextField} maxLength={5} />
              <FormField name="ort" component={TextField} maxLength={40} />

              <FormField
                name="kassennummer"
                component={TextField}
                maxLength={40}
              />
              <FormField
                name="versichertennummer"
                component={TextField}
                maxLength={40}
              />
              <FormField name="status" component={TextField} maxLength={40} />
              <FormField
                name="betriebsnummer"
                component={TextField}
                maxLength={40}
              />
              <FormField
                name="arztnummer"
                component={TextField}
                maxLength={40}
              />
              <FormField name="datum" component={TextField} maxLength={40} />

              <FormField
                name="freitext"
                component={TextField}
                multiline
                maxLength={500}
              />

              <FormField
                name="praxis_name"
                component={TextField}
                maxLength={40}
              />
              <FormField
                name="praxis_anschrift"
                component={TextField}
                maxLength={40}
              />
              <FormField
                name="praxis_plz"
                component={TextField}
                maxLength={5}
              />
              <FormField
                name="praxis_ort"
                component={TextField}
                maxLength={40}
              />

              <FormField
                name="gebuehrenfrei"
                component={RadioButtons}
                options={gebuehrenfreiChoices}
              />
            </div>
          </div>
          <NetworkError />
          <ValidationMessages form={form} />
          <div className="buttons d-flex flex-row-reverse px-4">
            <div className="right">
              <button
                className="btn btn-secondary"
                type="button"
                onClick={() => onRequestClose && onRequestClose()}
              >
                {prev}
              </button>
              <button
                className="btn btn-primary ml-2"
                type="submit"
                disabled={submitting}
              >
                {apply}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

RezeptEingabeForm.propTypes = {
  ...formPropTypes,
}

export default RezeptEingabeForm
