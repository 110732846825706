import React from 'react'
import compose from 'ramda/es/compose'
import join from 'ramda/es/join'
import map from 'ramda/es/map'
import nth from 'ramda/es/nth'
import split from 'ramda/es/split'
import toUpper from 'ramda/es/toUpper'
import './styles.scss'

const extractFileExtensions = map(compose(toUpper, nth(1), split('/')))
export const mkFileExtensionsString = compose(join(', '), extractFileExtensions)

export const formatBytesAsMebiBytes = bytes => (
  <span style={{ whiteSpace: 'nowrap' }}>{bytes / Math.pow(2, 20)} MB</span>
)

const Errors = ({ accept, errors, maxFiles, maxSize }) =>
  errors.map(({ code }, idx) => (
    <p className="error" key={idx}>
      {(code => {
        switch (code) {
          case 'file-invalid-type':
            return (
              <>
                Die Datei muss eines der folgenden Formate haben:{' '}
                {mkFileExtensionsString(accept)}
              </>
            )
          case 'file-too-large':
            return (
              <>
                Die maximale Dateigröße beträgt{' '}
                {formatBytesAsMebiBytes(maxSize)}
              </>
            )
          case 'too-many-files':
            return <>Es können maximal {maxFiles} Dateien hochgeladen werden</>
          default:
            console.log({ code })
            return (
              <>
                Es ist ein unbekannter Fehler aufgetreten, bitte wählen sie eine
                andere Datei!
              </>
            )
        }
      })(code)}
    </p>
  ))

export default Errors
